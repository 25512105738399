import { TransactionApiService } from '@api/transaction/transaction.api.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { TransactionDataResult } from '../../types/DataResult';
import { Transaction } from '../../types/Transaction';
import Consumption from '../../types/Consumption';

@Injectable()
export class TransactionRepositoryService {
  public constructor(private readonly transactionApi: TransactionApiService) {}

  public getActiveTransactions(params: {
    Issuer?: boolean;
    ChargingStationId?: string[];
    WithCompany?: boolean;
    WithSite?: boolean;
    WithSiteArea?: boolean;
    WithTag?: boolean;
    WithUser?: boolean;
    WithCar?: boolean;
    WithChargingStation?: boolean;
    Statistics?: 'ongoing';
    Limit: number;
  }): Observable<TransactionDataResult> {
    const requestParams = {
      ...params,
      ...(params.ChargingStationId !== undefined && { ChargingStationId: params.ChargingStationId.join('|') }),
    };

    return this.transactionApi.getActiveTransactions(requestParams);
  }

  public getTransaction(
    chargingStationId: string,
    params: { Limit: number; ConnectorID: number; SortFields?: string; WithCar?: boolean },
  ): Observable<TransactionDataResult> {
    return this.transactionApi.getTransaction(chargingStationId, params);
  }

  public getConsumptions(
    transactionId: number,
    params: { LoadAllConsumptions?: boolean } = {},
  ): Observable<Transaction> {
    return this.transactionApi.getConsumptions(transactionId, params);
  }

  public getLatestConsumptions(transactionId: number): Observable<Consumption | undefined> {
    return this.getConsumptions(transactionId, { LoadAllConsumptions: false }).pipe(
      catchError(() => {
        return of(undefined);
      }),
      map((transaction) => transaction?.values?.[transaction?.values?.length - 1]),
    );
  }
}
