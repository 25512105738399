import { Injectable } from '@angular/core';
import { ReservationApiService } from '@api/reservation/reservation.api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConnectorReservationDo } from '@api/reservation/dto/connector-reservation.do';
import { ComponentService } from '@services/component.service';
import { ChargePointStatus, ChargingStation } from '../../types/ChargingStation';
import { TenantComponents } from '../../types/Tenant';

@Injectable()
export class ReservationRepositoryService {
  public constructor(
    private readonly api: ReservationApiService,
    private componentService: ComponentService,
  ) {}

  public getReservations(chargingStation: ChargingStation): Observable<ConnectorReservationDo[]> {
    if (!this.componentService.isActive(TenantComponents.CONNECTOR_RESERVATION)) {
      return of([]);
    }

    const isAnyConnectorReserved = chargingStation.connectors.some(
      (connector) => connector.status === ChargePointStatus.RESERVED,
    );

    if (!isAnyConnectorReserved) {
      return of([]);
    }

    return forkJoin(
      chargingStation.connectors.map((connector) => {
        return this.api.getReservation(chargingStation.id, connector.connectorId).pipe(catchError(() => of(undefined)));
      }),
    ).pipe(map((reservations = []) => reservations.filter((reservation) => reservation !== undefined)));
  }
}
