import { HttpClient } from '@angular/common/http';
import { ConnectorReservationDo } from '@api/reservation/dto/connector-reservation.do';
import { buildUrl } from '@utils/url/url.util';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class ReservationApiService {
  public constructor(private httpClient: HttpClient) {}

  public getReservation(chargingStationId: string, connectorId: number): Observable<ConnectorReservationDo> {
    const url = buildUrl(RESTServerRoute.REST_GET_RESERVATION, {
      chargerId: chargingStationId,
      connectorId: connectorId.toString(),
    });
    return this.httpClient.get<ConnectorReservationDo>(url);
  }
}
