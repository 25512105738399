import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RESTServerRoute } from '../../types/Server';
import { ChargingProfileDataResult } from '../../types/DataResult';

@Injectable()
export class SmartChargingApiService {
  public constructor(public httpClient: HttpClient) {}

  public getChargingProfiles({
    chargingStationId,
  }: {
    chargingStationId: string;
  }): Observable<ChargingProfileDataResult> {
    const params = { ChargingStationID: chargingStationId };

    return this.httpClient.get<ChargingProfileDataResult>(RESTServerRoute.REST_CHARGING_PROFILES, { params });
  }
}
